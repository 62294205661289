<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="blue-grey"
      elevation="1"
      height="80"
    >
      <v-img
        alt="Logo"
        class="mr-3 hidden-xs-only"
        contain
        src="/images/logo.png"
        max-width="250"
        width="100%"
        @click.native="$router.push('/')"
      />

      <v-spacer />

      <div>
        <v-tabs
          optional
        >
          <v-tab
            to="/vizion/dashboard"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold blue-grey"
            v-if="user.data&&user.loggedIn"
          >
            Vizions
          </v-tab>
          <v-tab
            to="/vizion/profile"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold blue-grey"
            v-if="user.data&&user.loggedIn"
          >
            Profile
          </v-tab>
          <v-tab
            to="/vizion/users"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold blue-grey"
            v-if="user.data&&user.data.usertype=='Admin'"
          >
            Users
          </v-tab>
          <v-tab
            to="/vizion/system"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold blue-grey"
            v-if="user.data&&user.data.usertype=='Admin'"
          >
            System
          </v-tab>
          <v-tab
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold blue-grey"
            v-if="user.data&&user.loggedIn"
            @click="logout"
          >
            Logout
          </v-tab>
          <v-tab
            to="/login"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold blue-grey"
            v-else
          >
            Login
          </v-tab>
        </v-tabs>
      </div>
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  import { firebase } from '@/database/config/firebase'
  import store from '@/store'
  import { mapGetters } from 'vuex'

  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        { name: 'Home', to: '/', color: 'blue-grey', active: true, requireAdmin: false },
        { name: 'Vizions', to: '/vizion/dashboard', color: 'blue-grey', active: true, requireAdmin: false },
        { name: 'Users', to: '/vizion/users', color: 'blue-grey', active: false, requireAdmin: true },
        { name: 'System', to: '/vizion/system', color: 'blue-grey', active: false, requireAdmin: true },
        // { name: 'Contact', to: '/ContactUs', color: 'brown lighten-1', active: true },
        // { name: 'Apply', to: '/Apply', color: 'brown lighten-1', active: true },
        // { name: 'Portal', to: '/Portal', color: 'brown lighten-1', icon: 'send', active: false },
      ],
      social: [
        { name: 'Facebook', href: 'https://www.facebook.com/AmeriClaim', icon: '/socialicons/facebook.png' },
        { name: 'Twitter', href: 'https://twitter.com/AmeriClaim_IA', icon: '/socialicons/twitter.png' },
        { name: 'Linkedin', href: 'https://www.linkedin.com/company/292166', icon: '/socialicons/linkedin.png' },
      ],
    }),
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      }),

    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        // Set the Users and System menu options active if we are an admin
        this.items.forEach((item, idx) => {
          if (item.requireAdmin) {
            item.active = (this.user.data && this.user.data.usertype === 'Admin')
          }
        })
        // nconsole.log(this.items)
      },
      logout () {
        firebase
          .auth()
          .signOut()
          .then(() => {
            store.dispatch('fetchUser', null)
            this.$router.push({
              path: '/login',
              query: { message: 'logged out' },
            })
          })
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
